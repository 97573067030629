import jsPDF from 'jspdf';

export const generatePDF = (formData) => {
  const doc = new jsPDF();
  
  // Añade el contenido al PDF
  doc.text(`Nombre de la máquina: ${formData.nombreMaquina}`, 10, 10);
  doc.text(`Proyecto: ${formData.proyecto}`, 10, 20);
  doc.text(`Fecha de ingreso: ${formData.fechaIngreso}`, 10, 30);
  doc.text(`Fecha de salida: ${formData.fechaSalida}`, 10, 40);
  doc.text(`Observación: ${formData.observacion}`, 10, 50);
  // Añade más campos según sea necesario

  // Guarda el PDF
  doc.save('reporte.pdf');
};