import React, { useState } from 'react';
import GenericForm from '../ayuda/GenericForm';
import { saveReportOffline } from "../../idb";
import AlertModal from '../ayuda/AlertModal';
import ErrorModal from '../ayuda/ErrorModal';
import {formatDate} from '../ayuda/formatDate'

const MaquinariaInspectionForm = ({ onReportSaved, title }) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fields = [
        { name: 'sistemaArranque', type: 'checkbox', label: 'Sistema de arranque', observation: true },
        { name: 'nivelAceiteMotor', type: 'checkbox', label: 'Nivel de aceite del motor', observation: true },
        { name: 'goteosCombustible', type: 'checkbox', label: 'Goteos de combustible', observation: true },
        { name: 'danoEquipo', type: 'checkbox', label: 'Daño al equipo', observation: true },
        { name: 'tanqueCombustible', type: 'checkbox', label: 'Tanque de combustible', observation: true },
        { name: 'perillasAjuste', type: 'checkbox', label: 'Perillas de ajuste', observation: true },
        { name: 'mangueras', type: 'checkbox', label: 'Mangueras', observation: true },
        { name: 'carcasa', type: 'checkbox', label: 'Carcasa', observation: true },
        { name: 'operacionPlataformasAnticaidas', type: 'checkbox', label: 'La operación se realiza en plataformas anticaídas', observation: true },
        { name: 'conexionMangueraMotor', type: 'checkbox', label: 'Conexión de manguera con motor', observation: true },
        { name: 'bandejaAntiderrames', type: 'checkbox', label: 'Bandeja antiderrames', observation: true },
        { name: 'observaciones', type: 'textarea', label: 'Otras observaciones (escribirlas en forma breve)', required: false },
    ];

    const fieldLabels = fields.reduce((acc, field) => {
        acc[field.name] = field.label;
        return acc;
    }, {});


      const handleSubmit = async (formData) => {
        try {
            const report = {
                ...formData,
                creacion: formatDate(new Date()),
                title: title,
                fieldLabels: fieldLabels
            };

            await saveReportOffline(report);
            onReportSaved();
            setIsSuccessModalOpen(true);
        } catch (error) {
            console.error('Error al guardar el reporte:', error);
            setErrorMessage('Hubo un error al guardar el reporte. Por favor, inténtelo de nuevo.');
            setIsErrorModalOpen(true);
        }
    };

    return (
        <>
            <GenericForm 
                fields={fields} 
                onSubmit={handleSubmit} 
                title={title}
            />
            <AlertModal
                isOpen={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                message="El reporte de inspección se ha guardado exitosamente."
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                message={errorMessage}
            />
        </>
    );
};

export default MaquinariaInspectionForm;