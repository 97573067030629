// src/components/UpdatePrompt.js
import React from 'react';

const UpdatePrompt = ({ onUpdate, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="text-xl font-bold mb-4">Nueva actualización disponible</h2>
        <p className="mb-4">Hay una nueva versión de la aplicación disponible. ¿Desea actualizar ahora?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
          >
            Más tarde
          </button>
          <button
            onClick={onUpdate}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Actualizar
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpdatePrompt;