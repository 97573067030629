// src/components/Dashboard.js
import React, { useState, useMemo, useEffect } from "react";
import {
  FaClipboard,
  FaChartBar,
  FaTable,
  FaSearch,
  FaGlobe,
  FaList,
  FaSpinner,
  FaCog,
  FaGlobeAmericas,
} from "react-icons/fa";

import { Modal } from "./StaffModal";
import {getAllDailyActivities} from "../idb"
import {getOfflineReports} from "../idb"

export const forms = [
  {
    id: "FO_OPE_56",
    name: "FO-OPE-056 CHECK LIST - MOTOBOMBA",
    icon: <FaClipboard />,
  },
  {
    id: "FO_OPE_57",
    name: "FO-OPE-057 CHECK LIST - MEZCLADORA DE CONCRETO",
    icon: <FaClipboard />,
  },
  {
    id: "FO_OPE_58",
    name: "FO-OPE-058 CHECK LIST - PLANCHA COMPACTADORA",
    icon: <FaClipboard />,
  },
  {
    id: "FO_OPE_59",
    name: "FO-OPE-059 CHECK LIST - SIERRA CIRCULAR",
    icon: <FaClipboard />,
  },
  {
    id: "FO_OPE_60",
    name: "FO-OPE-060 CHECK LIST - TALADRO",
    icon: <FaClipboard />,
  },
  {
    id: "FO_OPE_61",
    name: "FO-OPE-061 CHECK LIST - VIBRADORA CONCRETO",
    icon: <FaClipboard />,
  },
  {
    id: "FO_OPE_62",
    name: "FO-OPE-062 CHECK LIST - AMOLADORA",
    icon: <FaClipboard />,
  },
  {
    id: "FO_OPE_63",
    name: "FO-OPE-063 CHECK LIST - TRONZADORA",
    icon: <FaClipboard />,
  },
  {
    id: "FO_OPE_64",
    name: "FO-OPE-064 HABILITACION DE ACERO",
    icon: <FaClipboard />,
  },
];

const Dashboard = ({
  iSA,
  onSelectPage,
  onShowReportTable,
  onShowSyncedReports,
  onShowDailyActivities,
  onShowDailyActivitiesOnline,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [modalState, setModalState] = useState({ isOpen: false, type: null });
  const [activities, setActivities] = useState([]);
  const [reports, setReports] = useState([]);

  const fetchActivities = async () => {
    const activities = await getAllDailyActivities();
    setActivities(activities);
  };
  const fetchReports  = async () => {
    const reports = await getOfflineReports();
    setReports(reports);
  };

  useEffect(() => {
    fetchActivities();
    fetchReports();
  }, []);

  const unsyncedActivitiesCount = activities.filter(activ => !activ.synced).length;
  const unsyncedReportsCount= reports.filter(activ => !activ.synced).length;

  const openModal = (type) => {
    setModalState({ isOpen: true, type });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, type: null });
  };

  const filteredForms = useMemo(() => {
    return forms.filter((form) =>
      form.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  return (
    <div className="bg-gray-100 p-4">
      <div className="grid grid-cols-2 gap-4 mb-6 text-center">
        <div className={`${
            unsyncedReportsCount > 0 ? "bg-red-500" : "bg-blue-400"
          } text-white p-4 rounded-lg shadow-md flex flex-col items-center justify-center`}>
          <div className="grid grid-cols-2">
            <div className="flex flex-col justify-center items-center">
              <FaChartBar className="text-3xl mb-2" />
              <span className="text-2xl font-bold">{reports.length}</span>
              <span className="text-[9px] md:text-sm ">Formularios Almacenados</span>
            </div>
            <div className="flex flex-col justify-center items-center">
              <FaSpinner className="text-3xl mb-2" />
              <span className="text-2xl font-bold">{unsyncedReportsCount}</span>
              <span className="text-[9px] md:text-sm">No Sincronizados</span>
            </div>
          </div>
        </div>
        <div className={`${
            unsyncedActivitiesCount > 0 ? "bg-red-500" : "bg-emerald-400"
          } text-white p-4 rounded-lg shadow-md flex flex-col items-center justify-center`}>
          <div className="grid grid-cols-2">
            <div className="flex flex-col justify-center items-center">
              <FaChartBar className="text-3xl mb-2" />
              <span className="text-2xl font-bold">{activities.length}</span>
              <span className="text-[9px] md:text-sm ">Partes Diarios Almacenados</span>
            </div>
            <div className="flex flex-col justify-center items-center">
              <FaSpinner className="text-3xl mb-2" />
              <span className="text-2xl font-bold">{unsyncedActivitiesCount}</span>
              <span className="text-[9px] md:text-sm">No Sincronizados</span>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-4 mb-6 text-[11px] md:text-ms ">
        <button
          onClick={onShowReportTable}
          className="w-full bg-green-500 text-white p-4 rounded-lg shadow-md flex flex-col items-center justify-center mb-6 ease-in-out transform hover:scale-105 hover:bg-emerald-500 hover:shadow-lg"
        >
          <FaTable className="text-3xl mb-2" />
          <span className="">Ver Mis Formularios</span>
        </button>
        <button
          onClick={onShowSyncedReports}
          className="w-full bg-cyan-500 text-white p-4 rounded-lg shadow-md flex flex-col items-center justify-center mb-6 ease-in-out transform hover:scale-105 hover:bg-cyan-600 hover:shadow-lg"
        >
          <FaGlobe className="text-3xl mb-2" />
          <span className="">Ver ONLINE</span>
        </button>
        <button
          onClick={onShowDailyActivities}
          className="w-full bg-pink-500 text-white p-4 rounded-lg shadow-md flex flex-col items-center justify-center mb-6 ease-in-out transform hover:scale-105 hover:bg-pink-600 hover:shadow-lg"
        >
          <FaList className="text-3xl mb-2" />
          <span className="">Registrar Parte Diario</span>
        </button>
        <button
          onClick={onShowDailyActivitiesOnline}
          className="w-full bg-lime-500 text-white p-4 rounded-lg shadow-md flex flex-col items-center justify-center mb-6 ease-in-out transform hover:scale-105 hover:bg-lime-600 hover:shadow-lg"
        >
          <FaGlobeAmericas className="text-3xl mb-2" />
          <span className="">Parte Diario Online</span>
        </button>
      </div>
      {iSA && (
        <>
          <button
            onClick={() => openModal("usuarios")}
            className="w-full bg-purple-500 text-white p-4 rounded-lg shadow-md flex items-center justify-center mb-6 ease-in-out transform hover:scale-105 hover:bg-purple-600 hover:shadow-lg flex-col m-auto"
          >
            <FaCog className="text-3xl mb-2" />
            <span className="">Gestionar Usuarios - Roles - Maquinas</span>
          </button>

          <Modal isOpen={modalState.isOpen} onClose={closeModal}></Modal>
        </>
      )}

      <h2 className="text-xl font-semibold mb-4">Formularios Disponibles</h2>

      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Buscar formulario..."
          className="w-full p-2 pl-10 pr-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>

      <div className="p-6 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 max-h-[60vh] overflow-y-auto">
        {filteredForms.map((form) => (
          <button
            key={form.id}
            onClick={() => onSelectPage(form.id)}
            className="bg-white hover:bg-gray-100 hover:shadow-xl text-gray-800 font-semibold py-2 px-4 rounded-lg shadow-md flex flex-col items-center justify-center transition duration-300 ease-in-out transform hover:scale-105"
          >
            <div className="text-3xl mb-2 text-blue-500">{form.icon}</div>
            <span className="text-xs text-center">{form.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
