import React, { useState } from 'react';
import GenericForm from '../ayuda/GenericForm';
import { saveReportOffline } from "../../idb";
import AlertModal from '../ayuda/AlertModal';
import ErrorModal from '../ayuda/ErrorModal';
import {formatDate} from '../ayuda/formatDate'

const MaquinariaInspectionForm = ({ onReportSaved, title }) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fields = [
        { name: 'bloqueoGatillo', type: 'checkbox', label: 'Bloqueo de Gatillo', observation: true },
        { name: 'interruptorEncendido', type: 'checkbox', label: 'Interruptor de encendido', observation: true },
        { name: 'salidaVirutas', type: 'checkbox', label: 'Salida de las virutas', observation: true },
        { name: 'carcasa', type: 'checkbox', label: 'Carcasa', observation: true },
        { name: 'base', type: 'checkbox', label: 'Base', observation: true },
        { name: 'protectorDisco', type: 'checkbox', label: 'Protector de disco', observation: true },
        { name: 'disco', type: 'checkbox', label: 'Disco', observation: true },
        { name: 'equipoEstable', type: 'checkbox', label: 'Equipo estable en el área de trabajo', observation: true },
        { name: 'topeParalelo', type: 'checkbox', label: 'Tope paralelo', observation: true },
        { name: 'marcosCorte', type: 'checkbox', label: 'Marcos para Corte', observation: true },
        { name: 'bloqueoTopeParalelo', type: 'checkbox', label: 'Bloqueo del tope paralelo', observation: true },
        { name: 'bloqueoInglete', type: 'checkbox', label: 'Bloqueo del Inglete', observation: true },
        { name: 'escalaInclinacion', type: 'checkbox', label: 'Escala de inclinación', observation: true },
        { name: 'bloqueoHusillo', type: 'checkbox', label: 'Bloqueo de Husillo', observation: true },
        { name: 'empunaduraAuxiliar', type: 'checkbox', label: 'Empuñadura Auxiliar', observation: true },
        { name: 'motorElectrico', type: 'checkbox', label: 'Motor Eléctrico', observation: true },
        { name: 'observaciones', type: 'textarea', label: 'Otras observaciones (escribirlas en forma breve)', required: false },
    ]

    const fieldLabels = fields.reduce((acc, field) => {
        acc[field.name] = field.label;
        return acc;
    }, {});


      const handleSubmit = async (formData) => {
        try {
            const report = {
                ...formData,
                creacion: formatDate(new Date()),
                title: title,
                fieldLabels: fieldLabels
            };

            await saveReportOffline(report);
            onReportSaved();
            setIsSuccessModalOpen(true);
        } catch (error) {
            console.error('Error al guardar el reporte:', error);
            setErrorMessage('Hubo un error al guardar el reporte. Por favor, inténtelo de nuevo.');
            setIsErrorModalOpen(true);
        }
    };

    return (
        <>
            <GenericForm 
                fields={fields} 
                onSubmit={handleSubmit} 
                title={title}
            />
            <AlertModal
                isOpen={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                message="El reporte de inspección se ha guardado exitosamente."
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                message={errorMessage}
            />
        </>
    );
};

export default MaquinariaInspectionForm;