  import axios from 'axios';
  import { getUser, getAllDailyActivities, updateDailyActivitySyncStatus } from './idb';

  async function syncActivities(loader) {
    if (loader) loader(true);

    const user = await getUser();
    const userDni = user[0].dni;

    await syncDailyActivities(userDni);

    if (loader) loader(false);
  }

  async function syncDailyActivities(userDni) {
    const dailyActivities = await getAllDailyActivities();
    const unsyncedActivities = dailyActivities.filter(activity => !activity.synced);

    if (unsyncedActivities.length === 0) {
      alert('Todos los Partes Diarios ya fueron sincronizados.');
      return;
    }

    for (const activity of unsyncedActivities) {
        try {
            const activityData = prepareActivityData(activity, userDni);
            const response = await sendJsonData('https://offline.smartaccesorios.shop/.phpOffSSOMA/dailyActivities.php', activityData);

            if (response.data.status === 'success') {
                await updateDailyActivitySyncStatus(activity.id, true);
                console.log('Actividades diarias', activity.id, 'sincronizadas correctamente:', activity);
            } else {
                console.error('Error en la respuesta del servidor para actividades diarias:', response.data.message || 'Respuesta no válida');
                console.log('Actividades diarias', activity.id, 'chingadas correctamente:', activity);
            }
        } catch (error) {
            handleSyncError(error, 'actividad diaria', activity.id);
        }
    }
}

function prepareActivityData(activity, userDni) {
    const uniqueId = `${userDni}_${activity.creacion}`;
    const data = {
        id: uniqueId, // Cambiado de unique_id a id
        machineryId: activity.machineryId,
        assistants: activity.assistants.join(','),
        date: activity.date,
        observations: activity.observations,
        morningShift: {
            start: activity.morningShift.start,
            end: activity.morningShift.end
        },
        afternoonShift: {
            start: activity.afternoonShift.start,
            end: activity.afternoonShift.end
        },
        warmupTime: activity.warmupTime,
        totalKm: activity.totalKm,
        totalHours: activity.totalHours,
        oilLevel: activity.oilLevel,
        initialFuel: activity.initialFuel,
        finalFuel: activity.finalFuel,
        operatorId: activity.operatorId,
        generalState: activity.generalState,
        activities: activity.activities.map(act => ({
            startTime: act.startTime,
            description: act.description,
            status: act.status
        })),
        // Remove operatorSignature
    };
    console.log('Datos de actividad preparados:', data);
    return data;
}

async function sendJsonData(url, data) {
    try {
      return await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      throw new Error(`Error al enviar datos JSON: ${error.message}`);
    }
}

  function handleSyncError(error, type, id) {
    if (error.response) {
      console.error(`Error de sincronización para ${type} ${id}:`, error.response.data.message || error.response.statusText);
    } else if (error.request) {
      console.error(`No se recibió respuesta del servidor para ${type} ${id}.`);
    } else {
      console.error(`Error al configurar la solicitud para ${type} ${id}:`, error.message);
    }
  }

  export default syncActivities;