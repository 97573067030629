import { openDB } from "idb";

const DB_NAME = "offline-reports";
const DB_VERSION = 3; // Incrementamos la versión para que se ejecute la actualización
const REPORT_STORE_NAME = "reports";
const USER_STORE_NAME = "users";
const DAILY_ACTIVITIES_STORE_NAME = "dailyActivities";
const MACHINERY_STORE_NAME = "machinery";
const OTHER_USERS_STORE_NAME = "otherUsers"

const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db, oldVersion, newVersion, transaction) {
    // Crear el object store para reportes si no existe
    if (!db.objectStoreNames.contains(REPORT_STORE_NAME)) {
      db.createObjectStore(REPORT_STORE_NAME, {
        keyPath: "id",
        autoIncrement: true,
      });
    }
    
    // Crear el object store para usuarios si no existe
    if (!db.objectStoreNames.contains(USER_STORE_NAME)) {
      db.createObjectStore(USER_STORE_NAME, {
        keyPath: "dni", // Usaremos el 'dni' como clave principal
      });
    }
    
    if (!db.objectStoreNames.contains(MACHINERY_STORE_NAME)) {
      db.createObjectStore(MACHINERY_STORE_NAME, {
        keyPath: "id",
      });
    }

    if (!db.objectStoreNames.contains(OTHER_USERS_STORE_NAME)) {
      db.createObjectStore(OTHER_USERS_STORE_NAME, {
        keyPath: "id",
      });
    }

    // Crear el object store para actividades diarias si no existe
    if (!db.objectStoreNames.contains(DAILY_ACTIVITIES_STORE_NAME)) {
      const dailyActivitiesStore = db.createObjectStore(
        DAILY_ACTIVITIES_STORE_NAME,
        {
          keyPath: "id",
          autoIncrement: true,
        }
      );
      
      dailyActivitiesStore.createIndex("date", "date", { unique: false });
      dailyActivitiesStore.createIndex("synced", "synced", { unique: false });
    } else {
      const dailyActivitiesStore = transaction.objectStore(
        DAILY_ACTIVITIES_STORE_NAME
      );
      if (!dailyActivitiesStore.indexNames.contains("synced")) {
        dailyActivitiesStore.createIndex("synced", "synced", { unique: false });
      }
    }
  },
});

// Función para registrar un nuevo usuario
export async function registerUser(userData) {
  const db = await dbPromise;
  await db.put(USER_STORE_NAME, userData);
}

// Función para obtener el usuario registrado
export async function getUser() {
  const db = await dbPromise;
  return await db.getAll("users");
}

// Función para guardar un reporte offline
export async function saveReportOffline(report) {
  const db = await dbPromise;
  const reportToSave = {
    ...report,
    images: Array.isArray(report.images) ? report.images : [],
    synced: false,
    timestamp: Date.now(),
  };
  await db.put(REPORT_STORE_NAME, reportToSave);
}

// Función para obtener todos los reportes offline
export async function getOfflineReports() {
  const db = await dbPromise;
  return await db.getAll(REPORT_STORE_NAME);
}

// Función para obtener con ID uno de los reportes offline
export async function getOfflineReportById(id) {
  const db = await dbPromise;
  return await db.get(REPORT_STORE_NAME, id);
}

// Función para actualizar con ID uno de los reportes offline
export async function updateReportSyncStatus(id, synced) {
  const db = await dbPromise;
  const report = await db.get(REPORT_STORE_NAME, id);
  if (report) {
    report.synced = synced;
    await db.put(REPORT_STORE_NAME, report);
  }
}

// Función para Borrar con ID uno de los reportes offline
export async function deleteReport(id) {
  const db = await dbPromise;
  await db.delete(REPORT_STORE_NAME, id);
}

//*************************************************/
// Nuevas funciones para manejar actividades diarias
export async function saveDailyActivity(activity) {
  const db = await dbPromise;
  return db.add(DAILY_ACTIVITIES_STORE_NAME, {
    ...activity,
    date: activity.date || new Date().toISOString().split('T')[0],
    synced: false,
    timestamp: Date.now(),
  });
}

export async function getAllDailyActivities() {
  const db = await dbPromise;
  return db.getAll(DAILY_ACTIVITIES_STORE_NAME);
}

export async function getUnsyncedDailyActivities() {
  const db = await dbPromise;
  const tx = db.transaction(DAILY_ACTIVITIES_STORE_NAME, 'readonly');
  const store = tx.objectStore(DAILY_ACTIVITIES_STORE_NAME);
  const index = store.index('synced');
  return index.getAll(IDBKeyRange.only(false));
}

export async function getDailyActivityById(id) {
  const db = await dbPromise;
  return db.get(DAILY_ACTIVITIES_STORE_NAME, id);
}

export async function modifyDailyActivity(id, updatedActivity) {
  const db = await dbPromise;
  const existingActivity = await db.get(DAILY_ACTIVITIES_STORE_NAME, id);
  if (!existingActivity) {
    throw new Error('Parte diario no encontrado');
  }
  const modifiedActivity = {
    ...existingActivity,
    ...updatedActivity,
    synced: false, // Marcar como no sincronizado al modificar
    timestamp: Date.now(),
  };
  return db.put(DAILY_ACTIVITIES_STORE_NAME, modifiedActivity);
}

export async function deleteDailyActivity(id) {
  const db = await dbPromise;
  return db.delete(DAILY_ACTIVITIES_STORE_NAME, id);
}

export async function updateDailyActivitySyncStatus(id, synced) {
  const db = await dbPromise;
  const activity = await db.get(DAILY_ACTIVITIES_STORE_NAME, id);
  if (activity) {
    activity.synced = synced;
    return db.put(DAILY_ACTIVITIES_STORE_NAME, activity);
  }
}

export async function addActivityToDailyActivity(dailyActivityId, newActivity) {
  const db = await dbPromise;
  const existingDailyActivity = await db.get(DAILY_ACTIVITIES_STORE_NAME, dailyActivityId);
  if (!existingDailyActivity) {
    throw new Error('Parte diario no encontrado');
  }
  existingDailyActivity.activities = [...(existingDailyActivity.activities || []), newActivity];
  existingDailyActivity.synced = false;
  existingDailyActivity.timestamp = Date.now();
  return db.put(DAILY_ACTIVITIES_STORE_NAME, existingDailyActivity);
}

export async function removeActivityFromDailyActivity(dailyActivityId, activityIndex) {
  const db = await dbPromise;
  const existingDailyActivity = await db.get(DAILY_ACTIVITIES_STORE_NAME, dailyActivityId);
  if (!existingDailyActivity) {
    throw new Error('Parte diario no encontrado');
  }
  existingDailyActivity.activities = existingDailyActivity.activities.filter((_, index) => index !== activityIndex);
  existingDailyActivity.synced = false;
  existingDailyActivity.timestamp = Date.now();
  return db.put(DAILY_ACTIVITIES_STORE_NAME, existingDailyActivity);
}

export async function editActivityInDailyActivity(dailyActivityId, activityIndex, updatedActivity) {
  const db = await dbPromise;
  const existingDailyActivity = await db.get(DAILY_ACTIVITIES_STORE_NAME, dailyActivityId);
  if (!existingDailyActivity) {
    throw new Error('Parte diario no encontrado');
  }
  existingDailyActivity.activities[activityIndex] = {
    ...existingDailyActivity.activities[activityIndex],
    ...updatedActivity
  };
  existingDailyActivity.synced = false;
  existingDailyActivity.timestamp = Date.now();
  return db.put(DAILY_ACTIVITIES_STORE_NAME, existingDailyActivity);
}

// Funciones para almacenar la nueva dara de usuarios y maquinaria

export async function saveUsers(otherUsers) {
  const db = await dbPromise;
  const tx = db.transaction(OTHER_USERS_STORE_NAME, 'readwrite');
  const store = tx.objectStore(OTHER_USERS_STORE_NAME);
  await Promise.all(otherUsers.map(user => store.put(user)));
  await tx.done;
}

export async function saveMachinery(machinery) {
  const db = await dbPromise;
  const tx = db.transaction(MACHINERY_STORE_NAME, 'readwrite');
  const store = tx.objectStore(MACHINERY_STORE_NAME);
  await Promise.all(machinery.map(item => store.put(item)));
  await tx.done;
}

export async function getUsers() {
  const db = await dbPromise;
  return db.getAll(OTHER_USERS_STORE_NAME);
}

export async function getMachinery() {
  const db = await dbPromise;
  return db.getAll(MACHINERY_STORE_NAME);
}