  import axios from 'axios';
  import { getOfflineReports, updateReportSyncStatus, getUser } from './idb';

  async function syncReports(loader) {
    if (loader) loader(true);

    const user = await getUser();
    const userDni = user[0].dni;

    await syncReportsData(userDni);

    if (loader) loader(false);
  }

  async function syncReportsData(userDni) {
    const reports = await getOfflineReports();
    const unsyncedReports = reports.filter(report => !report.synced);

    if (unsyncedReports.length === 0) {
      alert('Todos los Formularios ya fueron sincronizados.');
      return;
    }

    for (const report of unsyncedReports) {
      try {
        const formData = prepareReportFormData(report, userDni);
        const response = await sendFormData('https://offline.smartaccesorios.shop/.phpOffSSOMA/upload.php', formData);

        if (response.data.status === 'success') {
          await updateReportSyncStatus(report.id, true);
          console.log('Reporte', report.id, 'sincronizado correctamente:', report);
        } else {
          console.error('Error en la respuesta del servidor:', response.data.message);
        }
      } catch (error) {
        handleSyncError(error, 'reporte', report.id);
      }
    }
  }

function prepareReportFormData(report, userDni) {
  const formData = new FormData();
  const uniqueId = `${userDni}_${report.creacion}`;

  formData.append('unique_id', uniqueId);
  formData.append('form_id', report.title);
  formData.append('sender_name', userDni);
  formData.append('creation_date', report.creacion);

  Object.keys(report).forEach(key => {
    if (!['id', 'synced', 'images', 'creacion', 'title'].includes(key)) {
      formData.append(`responses[${key}]`, report[key]);
    }
  });

  if (report.observacion) {
    formData.append('observacion', report.observacion);
  }

  if (report.images && Array.isArray(report.images)) {
    report.images.forEach(image => {
      formData.append('images[]', image);
    });
  }

  return formData;
}

async function sendFormData(url, formData) {
    try {
      return await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (error) {
      throw new Error(`Error al enviar datos de formulario: ${error.message}`);
    }
}

  function handleSyncError(error, type, id) {
    if (error.response) {
      console.error(`Error de sincronización para ${type} ${id}:`, error.response.data.message || error.response.statusText);
    } else if (error.request) {
      console.error(`No se recibió respuesta del servidor para ${type} ${id}.`);
    } else {
      console.error(`Error al configurar la solicitud para ${type} ${id}:`, error.message);
    }
  }

  export default syncReports;