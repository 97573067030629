import React, { useState } from 'react';
import GenericForm from '../ayuda/GenericForm';
import { saveReportOffline } from "../../idb";
import AlertModal from '../ayuda/AlertModal';
import ErrorModal from '../ayuda/ErrorModal';
import {formatDate} from '../ayuda/formatDate'


const MaquinariaInspectionForm = ({ onReportSaved, title }) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fields = [
        { name: 'proyectoObra', type: 'text', label: 'PROYECTO/ OBRA:', required: true },
        { name: 'modelo', type: 'text', label: 'Modelo:', required: true },
        { name: 'ubicacion', type: 'text', label: 'Ubicación:', required: true},
        { name: 'marca', type: 'text', label: 'Marca:', required: true},
        { name: 'fecha', type: 'text', label: 'Fecha:', required: true},
        { name: 'nivelAceite', type: 'checkbox', label: 'Nivel de Aceite de motor está mayor al 50%', observation: true },
        { name: 'filtroAire', type: 'rating', label: 'Estado del filtro de Aire', required: true , observation: true },
        { name: 'filtroCombustible', type: 'rating', label: 'Estado de filtro de Combustible', required: true , observation: true },
        { name: 'rectratilArranque', type: 'checkbox', label: 'Rectratil de Arranque (No forzar, si el equipo no arranca)', observation: true },
        { name: 'manguerasCombustible', type: 'checkbox', label: 'Mangueras de Combustible (Verificar seguros y resequedad)', observation: true },
        { name: 'tapaCombustible', type: 'checkbox', label: 'Tapa de Combustible (Verificar roscado y sellado)', observation: true },
        { name: 'ahogador', type: 'rating', label: 'Ahogador (Verificar Estado)', required: true , observation: true },
        { name: 'palancaAcelerador', type: 'checkbox', label: 'Palanca de Acelerador (No tener revolucionado al equipos durante mucho tiempo)', observation: true },
        { name: 'limpiezaSalidaAgua', type: 'checkbox', label: 'Verificar Limpieza de la salida de Agua (Lado Mangas)', observation: true },
        { name: 'limpiezaEntradaAgua', type: 'checkbox', label: 'Verificar Limpieza de entrada de Agua A (Lado Anaconda)', observation: true },
        { name: 'limpiezaRodete', type: 'rating', label: 'Limpieza y estado del Rodete', required: true , observation: true },
        { name: 'equipoLimpio', type: 'checkbox', label: 'El equipo se encuentra limpio, caso contrario limpiar', observation: true },
        { name: 'equipoOperativo', type: 'checkbox', label: 'El equipo se encuentra operativo, sin fugas de aceites y/o combustible', observation: true },
        { name: 'observaciones', type: 'textarea', label: 'Otras observaciones (escribirlas en forma breve)', required: false },
      ];

      const fieldLabels = fields.reduce((acc, field) => {
        acc[field.name] = field.label;
        return acc;
    }, {});


      const handleSubmit = async (formData) => {
        try {
            const report = {
                ...formData,
                creacion: formatDate(new Date()),
                title: title,
                fieldLabels: fieldLabels
            };

            await saveReportOffline(report);
            onReportSaved();
            setIsSuccessModalOpen(true);
        } catch (error) {
            console.error('Error al guardar el reporte:', error);
            setErrorMessage('Hubo un error al guardar el reporte. Por favor, inténtelo de nuevo.');
            setIsErrorModalOpen(true);
        }
    };

    return (
        <>
            <GenericForm 
                fields={fields} 
                onSubmit={handleSubmit} 
                title={title}
            />
            <AlertModal
                isOpen={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                message="El reporte de inspección se ha guardado exitosamente."
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                message={errorMessage}
            />
        </>
    );
};

export default MaquinariaInspectionForm;