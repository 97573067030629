import React, { useState } from 'react';
import GenericForm from '../ayuda/GenericForm';
import { saveReportOffline } from "../../idb";
import AlertModal from '../ayuda/AlertModal';
import ErrorModal from '../ayuda/ErrorModal';
import {formatDate} from '../ayuda/formatDate'

const MaquinariaInspectionForm = ({ onReportSaved, title }) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fields = [
        { name: 'enchufeIndustrial', type: 'checkbox', label: 'Enchufe industrial', observation: true },
        { name: 'encendidoEquipo', type: 'checkbox', label: 'Encendido de equipo', observation: true },
        { name: 'equipoLimpio', type: 'checkbox', label: 'El equipo se encuentra limpio y sin ninguna rajadura, y/o desperfecto', observation: true },
        { name: 'ranurasVentilacion', type: 'checkbox', label: 'Las ranuras de ventilación están sin obstrucción', observation: true },
        { name: 'botonEncendidoApagado', type: 'checkbox', label: 'El botón de encendido y apagado funciona correctamente', observation: true },
        { name: 'guardaSeguridadDisco', type: 'checkbox', label: 'Posee guarda de seguridad para el disco', observation: true },
        { name: 'discoAbrasivo', type: 'checkbox', label: 'Disco abrasivo', observation: true },
        { name: 'orificioMangoAuxiliar', type: 'checkbox', label: 'Orificio para mango auxiliar', observation: true },
        { name: 'botonBloqueoHusillo', type: 'checkbox', label: 'Botón de bloqueo de husillo', observation: true },
        { name: 'mangoAuxiliar', type: 'checkbox', label: 'Mango auxiliar', observation: true },
        { name: 'cableAlimentacion', type: 'checkbox', label: 'Cable de alimentación', observation: true },
        { name: 'discoAdecuado', type: 'checkbox', label: 'El disco a utilizar ¿Es el adecuado a las revoluciones de la máquina?', observation: true },
        { name: 'areaTrabajoLibre', type: 'checkbox', label: 'El área de trabajo ¿Se encuentra libre de materiales inflamables?', observation: true },
        { name: 'proteccionProyeccion', type: 'checkbox', label: '¿Existe protección contra la proyección de partículas a equipos y personas?', observation: true },
        { name: 'llaveExtraccionDisco', type: 'checkbox', label: '¿Se cuenta con la llave de extracción de disco?', observation: true },
        { name: 'desconexionEmergencia', type: 'checkbox', label: 'En caso de emergencia ¿Se puede desconectar la alimentación eléctrica en forma rápida?', observation: true },
        { name: 'observaciones', type: 'textarea', label: 'Otras observaciones (escribirlas en forma breve)', required: false },
    ];

    const fieldLabels = fields.reduce((acc, field) => {
        acc[field.name] = field.label;
        return acc;
    }, {});


      const handleSubmit = async (formData) => {
        try {
            const report = {
                ...formData,
                creacion: formatDate(new Date()),
                title: title,
                fieldLabels: fieldLabels
            };

            await saveReportOffline(report);
            onReportSaved();
            setIsSuccessModalOpen(true);
        } catch (error) {
            console.error('Error al guardar el reporte:', error);
            setErrorMessage('Hubo un error al guardar el reporte. Por favor, inténtelo de nuevo.');
            setIsErrorModalOpen(true);
        }
    };

    return (
        <>
            <GenericForm 
                fields={fields} 
                onSubmit={handleSubmit} 
                title={title}
            />
            <AlertModal
                isOpen={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                message="El reporte de inspección se ha guardado exitosamente."
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                message={errorMessage}
            />
        </>
    );
};

export default MaquinariaInspectionForm;