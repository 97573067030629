import React, { useState } from 'react';
import GenericForm from '../ayuda/GenericForm';
import { saveReportOffline } from "../../idb";
import AlertModal from '../ayuda/AlertModal';
import ErrorModal from '../ayuda/ErrorModal';
import {formatDate} from '../ayuda/formatDate'

const MaquinariaInspectionForm = ({ onReportSaved, title }) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fields = [
        { name: 'enchufeIndustrial', type: 'checkbox', label: 'Enchufe industrial', observation: true },
        { name: 'encendidoEquipo', type: 'checkbox', label: 'Encendido de equipo', observation: true },
        { name: 'mesaSoporte', type: 'checkbox', label: 'Mesa para soporte del equipo', observation: true },
        { name: 'limpiezaEquipo', type: 'checkbox', label: 'Limpieza de Equipo', observation: true },
        { name: 'cabezalCorte', type: 'checkbox', label: 'Cabezal de corte', observation: true },
        { name: 'topeProfundidad', type: 'checkbox', label: 'Tope de profundidad', observation: true },
        { name: 'cableAlimentacion', type: 'checkbox', label: 'Cable de alimentación', observation: true },
        { name: 'enchufeConexionInterna', type: 'checkbox', label: 'Enchufe y conexión interna', observation: true },
        { name: 'interruptorEncendidoApagado', type: 'checkbox', label: 'Interruptor de encendido y apagado', observation: true },
        { name: 'botonBloqueo', type: 'checkbox', label: 'Botón de bloqueo', observation: true },
        { name: 'botonDesbloqueo', type: 'checkbox', label: 'Botón de desbloqueo', observation: true },
        { name: 'mangoControl', type: 'checkbox', label: 'Mango de Control', observation: true },
        { name: 'guardaFija', type: 'checkbox', label: 'Guarda Fija', observation: true },
        { name: 'guardaRetractil', type: 'checkbox', label: 'Guarda retractil', observation: true },
        { name: 'mediaTuerca', type: 'checkbox', label: 'Media tuerca', observation: true },
        { name: 'tornilloSujecion', type: 'checkbox', label: 'Tornillo de Sujeción', observation: true },
        { name: 'mordazaFrontal', type: 'checkbox', label: 'Mordaza Frontal', observation: true },
        { name: 'guiaCortesAngulo', type: 'checkbox', label: 'Guía para cortes en ángulo', observation: true },
        { name: 'deflectorChispas', type: 'checkbox', label: 'Deflector de chispas', observation: true },
        { name: 'disco', type: 'checkbox', label: 'Disco', observation: true },
        { name: 'palancaBloqueoEje', type: 'checkbox', label: 'Palanca de Bloqueo del eje', observation: true },
        { name: 'manivela', type: 'checkbox', label: 'Manivela', observation: true },
        { name: 'mangoTransportacion', type: 'checkbox', label: 'Mango de Transportación', observation: true },
        { name: 'caretaEsmerilar', type: 'checkbox', label: 'Personal cuenta con careta esmerilar', observation: true },
        { name: 'mandil', type: 'checkbox', label: 'Personal cuenta con mandil', observation: true },
        { name: 'observaciones', type: 'textarea', label: 'Otras observaciones (escribirlas en forma breve)', required: false },
    ];

    const fieldLabels = fields.reduce((acc, field) => {
        acc[field.name] = field.label;
        return acc;
    }, {});


      const handleSubmit = async (formData) => {
        try {
            const report = {
                ...formData,
                creacion: formatDate(new Date()),
                title: title,
                fieldLabels: fieldLabels
            };

            await saveReportOffline(report);
            onReportSaved();
            setIsSuccessModalOpen(true);
        } catch (error) {
            console.error('Error al guardar el reporte:', error);
            setErrorMessage('Hubo un error al guardar el reporte. Por favor, inténtelo de nuevo.');
            setIsErrorModalOpen(true);
        }
    };

    return (
        <>
            <GenericForm 
                fields={fields} 
                onSubmit={handleSubmit} 
                title={title}
            />
            <AlertModal
                isOpen={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                message="El reporte de inspección se ha guardado exitosamente."
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                message={errorMessage}
            />
        </>
    );
};

export default MaquinariaInspectionForm;