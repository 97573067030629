import React, { useState } from 'react';
import GenericForm from '../ayuda/GenericForm';
import { saveReportOffline } from "../../idb";
import AlertModal from '../ayuda/AlertModal';
import ErrorModal from '../ayuda/ErrorModal';
import {formatDate} from '../ayuda/formatDate'

const MaquinariaInspectionForm = ({ onReportSaved, title }) => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const fields = [
        { name: 'conoceRiesgos', type: 'checkbox', label: 'El operador conoce los riesgos y peligros al usar esta maquinaria', observation: true },
        { name: 'revisionPrevia', type: 'checkbox', label: 'El operador a revisado el equipo antes de utilizarlo y llenado su ATS', observation: true },
        { name: 'estadoGuardas', type: 'checkbox', label: 'Las guardas de la parte del disco y del motor no tienen abolladuras, golpes, etc.', observation: true },
        { name: 'fugasGasolina', type: 'checkbox', label: 'Verificar que no haya fugas de gasolina en el tanque y el motor', observation: true },
        { name: 'cordelArrancador', type: 'checkbox', label: 'Verificar que el cordel arrancador se encuentre en perfectas condiciones de uso', observation: true },
        { name: 'timonAnclado', type: 'checkbox', label: 'Verificar que el timón se encuentre correctamente anclado en su eje', observation: true },
        { name: 'mezcladoraBandeja', type: 'checkbox', label: 'Verificar si la mezcladora está sobre su bandeja', observation: true },
        { name: 'condicionesAreaTrabajo', type: 'checkbox', label: 'Verificar si las condiciones del área de trabajo son aptas para operar la mezcladora de concreto', observation: true },
        { name: 'eppsAdecuados', type: 'checkbox', label: 'Verificar que el personal cuente con sus EPP\'s adecuados para la labor', observation: true },
        { name: 'bandejaAntiderrames', type: 'checkbox', label: 'Bandeja antiderrames para el combustible', observation: true },
        { name: 'observaciones', type: 'textarea', label: 'Observaciones generales', required: false },
    ];

    const fieldLabels = fields.reduce((acc, field) => {
        acc[field.name] = field.label;
        return acc;
    }, {});


      const handleSubmit = async (formData) => {
        try {
            const report = {
                ...formData,
                creacion: formatDate(new Date()),
                title: title,
                fieldLabels: fieldLabels
            };

            await saveReportOffline(report);
            onReportSaved();
            setIsSuccessModalOpen(true);
        } catch (error) {
            console.error('Error al guardar el reporte:', error);
            setErrorMessage('Hubo un error al guardar el reporte. Por favor, inténtelo de nuevo.');
            setIsErrorModalOpen(true);
        }
    };

    return (
        <>
            <GenericForm 
                fields={fields} 
                onSubmit={handleSubmit} 
                title={title}
            />
            <AlertModal
                isOpen={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
                message="El reporte de inspección se ha guardado exitosamente."
            />
            <ErrorModal
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                message={errorMessage}
            />
        </>
    );
};

export default MaquinariaInspectionForm;