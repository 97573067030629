import React, { useState, useEffect } from 'react';
import { TrashIcon } from "@heroicons/react/16/solid";
import Loader from "../ayuda/Loader";

const ImageUploader = ({ images, setImages }) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Create previews for existing images
    const existingPreviews = images.map(image => 
      image instanceof File ? URL.createObjectURL(image) : image
    );
    setImagePreviews(existingPreviews);

    // Cleanup function to revoke object URLs
    return () => {
      existingPreviews.forEach(preview => {
        if (preview.startsWith('blob:')) {
          URL.revokeObjectURL(preview);
        }
      });
    };
  }, [images]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setIsLoading(true);
    Promise.all(files.map((file) => compressImage(file)))
      .then((compressedImages) => {
        setImages((prevImages) => [...prevImages, ...compressedImages]);
        setImagePreviews((prevPreviews) => [
          ...prevPreviews,
          ...compressedImages.map((image) => URL.createObjectURL(image)),
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const scaleFactor = 0.7;

          // Redimensionar el canvas
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;

          // Dibujar la imagen redimensionada en el canvas
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Convertir la imagen a formato webp
          canvas.toBlob(
            (blob) => {
              // Obtener el nombre del archivo original y cambiar la extensión a .webp
              const newFileName = file.name.replace(/\.[^.]+$/, ".webp"); // Cambia la extensión a .webp

              // Crear el nuevo archivo con la extensión .webp
              resolve(
                new File([blob], newFileName, {
                  type: "image/webp",
                  lastModified: Date.now(),
                })
              );
            },
            "image/webp",
            0.8
          );
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const removeImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) => {
      const newPreviews = prevPreviews.filter((_, i) => i !== index);
      if (prevPreviews[index] && prevPreviews[index].startsWith('blob:')) {
        URL.revokeObjectURL(prevPreviews[index]);
      }
      return newPreviews;
    });
  };

  return (
    <>
      {isLoading && <Loader text="Comprimiendo Imagenes, espere por favor" />}
      <input
        type="file"
        onChange={handleImageChange}
        multiple
        accept="image/*"
        className="w-full p-4 text-sm bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />
      <div className="flex flex-wrap gap-2 mt-4">
        {imagePreviews.map((preview, index) => (
          <div key={index} className="relative">
            <img
              src={preview}
              alt={`Preview ${index + 1}`}
              className="w-20 h-20 object-cover rounded"
            />
            <button
              onClick={() => removeImage(index)}
              className="absolute top-0.5 right-0.5 bg-red-500 text-white rounded-full p-1"
            >
              <TrashIcon className="h-3 w-3" />
            </button>
          </div>
        ))}
      </div>
      {images.length > 0 && (
        <p className="text-sm text-gray-500">
          {images.length} imagen(es) añadida(s)
        </p>
      )}
    </>
  );
};

export default ImageUploader;