// src/components/SyncedReportsList.js
import React, { useState, useEffect } from "react";
import { FiPrinter, FiTrash, FiEye } from "react-icons/fi";
import ModalSyncedReports from "./ayuda/ModalSyncedReports";
import Loader from './ayuda/Loader'

const SyncedReportsList = ( { usersios } ) => {
  
  const [syncedReports, setSyncedReports] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    fetchSyncedReports();
  }, []);

  const fetchSyncedReports = async () => {
    try {
      setLoading(true)
      const response = await fetch(
        "https://offline.smartaccesorios.shop/.phpOffSSOMA/get_synced_reports.php"
      );
      const data = await response.json();
      setSyncedReports(data);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching synced reports:", error);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text || "";
  };

  const handleGeneratePDF = (report) => {
    // Implementar la generación de PDF
    console.log("Generar PDF para:", report);
  };

  const handleDeleteReport = (report) => {
    // Implementar la eliminación del reporte
    console.log("Eliminar reporte:", report);
  };

  const handleViewReport = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
    console.log(report.responses[7].value);
  };

  const getUserNameByDni = (dni) => {
    const user = usersios.find((user) => user.dni === dni);
    return user ? `${user.nombres} ${user.apPaterno} ${user.apMaterno}` : "Usuario no encontrado";
  };

  if (loading) {
    return <Loader text="Cargando..." />;
  }

  const renderReportCard = (report) => {
    return (
      <div
        key={report.id}
        className="p-4 bg-white rounded-lg shadow-xl drop-shadow-xl flex items-center mb-4"
      >
        <div className="flex-shrink-0 mr-4">
          {report.image_paths && report.image_paths.length > 0 ? (
            <div>
              <img
                className="w-16 h-16 rounded-full object-cover"
                src={`https://offline.smartaccesorios.shop/.phpOffSSOMA${report.image_paths[0].substring(1)}`}
                alt="Imagen principal"
              />
              <div className="flex space-x-1 mt-2">
                {report.image_paths.slice(1, 4).map((image, imgIndex) => (
                  <img
                    key={imgIndex}
                    className="w-5 h-5 rounded object-cover"
                    src={`https://offline.smartaccesorios.shop/.phpOffSSOMA${image.substring(1)}`}
                    alt={`Imagen secundaria ${imgIndex + 1}`}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center">
              <span className="text-gray-500 text-xs text-center">
                Sin imágenes
              </span>
            </div>
          )}
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div>
            <h3 className="text-sm font-semibold">
              {truncateText(report.form_id, 30)}
            </h3>
            <p className="text-xs text-gray-400">{report.creation_date}</p>
            <p className="text-xs text-gray-600">
              Enviado por: {getUserNameByDni(report.sender_name)}
            </p>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="flex space-x-2">
              <button
                onClick={() => handleGeneratePDF(report)}
                className="text-blue-500 hover:text-blue-700"
              >
                <FiPrinter className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleDeleteReport(report)}
                className="text-red-500 hover:text-red-700"
              >
                <FiTrash className="h-5 w-5" />
              </button>
              <button
                onClick={() => handleViewReport(report)}
                className="text-green-500 hover:text-green-700"
              >
                <FiEye className="h-5 w-5" />
              </button>
            </div>
            <div className="w-3 h-3 rounded-full bg-green-500"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">Formularios Sincronizados</h2>
      {syncedReports.map(renderReportCard)}
      <ModalSyncedReports
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        report={selectedReport}
      />
    </div>
  );
};

export default SyncedReportsList;